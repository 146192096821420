interface Props {
  img: string;
  title: string;
}
const GameTile = (props: Props) => (
  <div>
    <img
      src={props.img}
      className={'rounded shadow overflow-hidden'}
      alt={'game tile'}
    />
    <p className={'text-center text-dark mt-2'}>{props.title}</p>
  </div>
);

export default GameTile;
