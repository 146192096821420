import { PaymentFrequency } from 'src/generated/graphql';
import { getI18n } from 'react-i18next';

export const subscriptionRecurringRewardLabel = (
  frequency: PaymentFrequency
) => {
  const i18n = getI18n();

  const label = (() => {
    switch (frequency) {
      case PaymentFrequency.Annual:
        return i18n.t<string>('subscription.reward-frequency.annual');
      case PaymentFrequency.SemiAnnual:
        return i18n.t<string>('subscription.reward-frequency.semiannual');
      case PaymentFrequency.BiAnnual:
        return i18n.t<string>('subscription.reward-frequency.biannual');
      case PaymentFrequency.Monthly:
        return i18n.t<string>('subscription.reward-frequency.monthly');
      case PaymentFrequency.BiMonthly:
        return i18n.t<string>('subscription.reward-frequency.bimonthly');
      case PaymentFrequency.Weekly:
        return i18n.t<string>('subscription.reward-frequency.weekly');
      case PaymentFrequency.Daily:
        return i18n.t<string>('subscription.reward-frequency.daily');
      default:
        return `${frequency}`;
    }
  })();

  return label;
};

export const subscriptionRecurringPaymentFrequencyLabel = (
  frequency: PaymentFrequency
) => {
  const i18n = getI18n();

  const label = (() => {
    switch (frequency) {
      case PaymentFrequency.Annual:
        return i18n.t<string>('subscription.payment-frequency.annual', '/y');
      case PaymentFrequency.SemiAnnual:
        return i18n.t<string>(
          'subscription.payment-frequency.semiannual',
          'Semi Annually'
        );
      case PaymentFrequency.BiAnnual:
        return i18n.t<string>(
          'subscription.payment-frequency.biannual',
          'BiAnnually'
        );
      case PaymentFrequency.Monthly:
        return i18n.t<string>('subscription.payment-frequency.monthly', '/mo');
      case PaymentFrequency.BiMonthly:
        return i18n.t<string>(
          'subscription.payment-frequency.bimonthly',
          'BiMonthly'
        );
      case PaymentFrequency.Weekly:
        return i18n.t<string>('subscription.payment-frequency.weekly', '/week');
      case PaymentFrequency.Daily:
        return i18n.t<string>('subscription.payment-frequency.daily', '/day');
      default:
        return `${frequency}`;
    }
  })();

  return label;
};
