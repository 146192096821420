import Row from 'react-bootstrap/Row';

import Package from './Plan';
import { Col, Container } from 'react-bootstrap';
import { useGetAllSubscriptionTypesQuery } from '../../../../../generated/graphql';
import AsyncView from '../../../../../components/AsyncView/AsyncView';
import { AnytimeDataStat } from 'HiveClient/pages/ManageSubscription/AnytimeDataStat';
import { getAllEnrollableSubscriptionTypes } from '../../../../../services/SubscriptionService';
import LanguageService from '../../../../../services/LanguageService';

const Plans = () => {
  const getSubscriptionTypes = useGetAllSubscriptionTypesQuery();

  return (
    <AsyncView loading={getSubscriptionTypes.loading}>
      <Container>
        {getAllEnrollableSubscriptionTypes(getSubscriptionTypes).map(
          (subscriptionType) => (
            <Row key={subscriptionType.id}>
              <Col className={'mb-4'}>
                <Package
                  id={subscriptionType.id}
                  title={LanguageService.getLocaleObject(
                    subscriptionType.localizedNames
                  )}
                  description={
                    LanguageService.getLocaleObject(
                      subscriptionType.localizedDescriptions
                    ) ?? ''
                  }
                  costs={
                    subscriptionType.explicitEnrollment
                      ? subscriptionType.explicitEnrollment.costs
                      : subscriptionType.implicitEnrollments.length
                      ? subscriptionType.implicitEnrollments[0]
                          .externalSubscriptionPackage.costs
                      : []
                  }
                  stats={
                    <AnytimeDataStat subscriptionType={subscriptionType} />
                  }
                />
              </Col>
            </Row>
          )
        )}
      </Container>
    </AsyncView>
  );
};

export default Plans;
