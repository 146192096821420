import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  icon: IconDefinition;
  title: string;
  body: string;
}

const Feature = (props: Props) => (
  <Row className={'g-0'}>
    <Col xs={2}>
      <FontAwesomeIcon
        className={'text-primary'}
        style={{ width: '100%', height: 'auto' }}
        icon={props.icon}
      />
    </Col>
    <Col xs={10}>
      <div className={'ms-4'}>
        <h3 className={'text-primary'}>{props.title}</h3>
        <p>{props.body}</p>
      </div>
    </Col>
  </Row>
);

export default Feature;
