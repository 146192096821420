import { Slide, Slider } from 'pure-react-carousel';

export interface ICarouselSliderProps {
  children: JSX.Element | JSX.Element[];
  fullHeight?: boolean;
  classes?: {
    slider?: string;
    sliderTray?: string;
    slide?: string;
    animation?: string;
    trayWrap?: string;
  };
}

const CarouselSlider = (props: ICarouselSliderProps) => (
  <Slider
    className={props.classes?.slider}
    classNameTray={props.classes?.sliderTray}
    classNameAnimation={props.classes?.animation}
    classNameTrayWrap={props.classes?.trayWrap}
  >
    {Array.isArray(props.children) ? (
      props.children.map((child, index) => (
        <Slide
          key={`slide_${index}`}
          index={index}
          className={[
            'tw-p-0',
            props.fullHeight ? 'min-vh-100' : props.classes?.slide ?? 'h-100',
          ].join(' ')}
        >
          {child}
        </Slide>
      ))
    ) : (
      <Slide
        index={0}
        className={['p-0', props.fullHeight ? 'min-vh-100' : 'h-100'].join(' ')}
      >
        {props.children}
      </Slide>
    )}
  </Slider>
);

export default CarouselSlider;
