import { Fragment } from 'react';
import Hero from './Hero/Hero';
import BottomBanner from './BottomBanner/BottomBanner';
import Games from './Games/Games';
import Features from './Features/Features';
import Plans from './Plans/Plans';

const Product = () => (
  <Fragment>
    <Hero />
    <Games />
    <Features />
    <Plans />
    <BottomBanner />
  </Fragment>
);

export default Product;
