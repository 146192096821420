import { Col, Row } from 'react-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Features.module.scss';

interface Props {
  title: string;
  body: JSX.Element;
  icon: IconDefinition;
  backgroundImage: string;
}
const FeaturesModalSlide = (props: Props) => (
  <Row className={'g-0'}>
    <Col xs={0} sm={3} md={5}>
      <div
        className={styles.iconContainer}
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      >
        <FontAwesomeIcon className={styles.icon} icon={props.icon} />
      </div>
    </Col>
    <Col xs={12} sm={9} md={7}>
      <div className={styles.copyContainer}>
        <h2 className={styles.slideTitle}>{props.title}</h2>
        {props.body}
      </div>
    </Col>
  </Row>
);

export default FeaturesModalSlide;
