import { useTranslation } from 'react-i18next';
import { Props } from 'HiveClient/components/FooterContent/ContactLinks';

const ContactLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <nav className={props.className}>
      <h6>{t('footer.contact-us.heading', 'Contact Us')}</h6>
      <a
        href={'mailto:contact@sltesports.gg'}
        data-semantic={'footer-contact-us-link'}
      >
        {t('footer.contact-us.feedback-link-label')}
      </a>
      <a
        href={'https://discord.gg/UJr33KQrky'}
        target='blank'
        data-semantic={'footer-event-support-link'}
      >
        {t('footer.contact-us.event-support-link-label')}
      </a>
      <a
        href={`http://esports-help.slt.lk/`}
        target='blank'
        data-semantic={'footer-help-center-link'}
      >
        {t('footer.contact-us.help-center-link-label')}
      </a>
    </nav>
  );
};

export default ContactLinks;
