import { Container, Modal, ModalProps } from 'react-bootstrap';

const AnytimeDataModal = (props: ModalProps) => (
  <Modal {...props} size={'lg'}>
    <Modal.Header closeButton />
    <Modal.Body className={'my-4 mx-lg-5'}>
      <Container>
        <div className={'text-center mb-3'}>
          <h2 className={'h1 text-primary'}>Additional Anytime Data</h2>
          <p>
            As part of the packages, you can enjoy additional Anytime Data for
            the following games and gaming related services.
          </p>
        </div>
        <hr className={'border-dark border-2'} />
        <div style={{ columnCount: 3 }} className={'mt-2'}>
          <span className={'d-block'}>PUBG Mobile</span>
          <span className={'d-block'}>PUBG</span>
          <span className={'d-block'}>Garena Free Fire</span>
          <span className={'d-block'}>ROBLOX</span>
          <span className={'d-block'}>League of Legends</span>
          <span className={'d-block'}>Valorant</span>
          <span className={'d-block'}>Legends of Runterra</span>
          <span className={'d-block'}>Teamfight Tactics</span>
          <span className={'d-block'}>Call of Duty Mobile</span>
          <span className={'d-block'}>Call of Duty</span>
          <span className={'d-block'}>Titanfall</span>
          <span className={'d-block'}>Nintendo Games</span>
          <span className={'d-block'}>PlayStation Network</span>
          <span className={'d-block'}>Miniclip Games</span>
          <span className={'d-block'}>Mobile Legends:BB</span>
          <span className={'d-block'}>Steam</span>
          <span className={'d-block'}>Origin</span>
          <span className={'d-block'}>Epic Games</span>
          <span className={'d-block'}>Ubisoft Games</span>
          <span className={'d-block'}>Gameloft</span>
          <span className={'d-block'}>Clash of Clans</span>
          <span className={'d-block'}>Clash Royale</span>
          <span className={'d-block'}>Brawl Stars</span>
          <span className={'d-block'}>Fortnite PC</span>
          <span className={'d-block'}>Minecraft PC</span>
          <span className={'d-block'}>Overwatch</span>
          <span className={'d-block'}>Lords Mobile</span>
          <span className={'d-block'}>Grand Theft Auto V</span>
          <span className={'d-block'}>Hearthstone PC</span>
          <span className={'d-block'}>Arena of Valor</span>
          <span className={'d-block'}>DOTA 2</span>
          <span className={'d-block'}>World Of Warcraft</span>
          <span className={'d-block'}>Rainbow Six Siege</span>
          <span className={'d-block'}>Counter-Strike</span>
          <span className={'d-block'}>Counter-Strike CZ</span>
          <span className={'d-block'}>Counter-Strike Source</span>
          <span className={'d-block'}>Counter-Strike GO</span>
          <span className={'d-block'}>StarCraft II</span>
          <span className={'d-block'}>Vainglory</span>
          <span className={'d-block'}>Rocket League</span>
          <span className={'d-block'}>Tekken 7</span>
          <span className={'d-block'}>Twitch</span>
        </div>
      </Container>
    </Modal.Body>
  </Modal>
);

export default AnytimeDataModal;
