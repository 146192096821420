import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Feature from './Feature';
import {
  faSignal,
  faSignalStream,
  faTrophy,
  faUserLock,
  faUsers,
} from 'HiveClient/components/Icons/Icons';
import FancyButton from 'HiveClient/components/FancyButton/FancyButton';
import FeaturesModal from './FeaturesModal';
import styles from './Features.module.scss';

const Features = () => {
  const [showFeaturesModal, setShowFeaturesModal] = useState<boolean>(false);
  return (
    <Container className={'mb-5'}>
      <Row>
        <Col md={6} lg={4} className={'mb-5'}>
          <h2
            className={['text-primary m-0', styles.highlightedTitle].join(' ')}
          >
            FEATURES
          </h2>
          <p>
            SLT Gamer Bundles are loaded with features with gaming at its core.
          </p>
          <FancyButton
            semantic={'features-view-details'}
            className={'ps-0'}
            onClick={() => setShowFeaturesModal(true)}
          >
            View Details
          </FancyButton>
        </Col>
        <Col md={6} lg={4} className={'mb-5'}>
          <Feature
            icon={faSignalStream}
            title={'OP Levels of Data'}
            body={
              'Unlock your gaming with additional data specific to playing, downloading games, and watching streams.'
            }
          />
        </Col>
        <Col md={6} lg={4} className={'mb-5'}>
          <Feature
            icon={faSignal}
            title={'Optimized Latency'}
            body={
              'Minimize your lag or latency issues for the perfect gaming experience.'
            }
          />
        </Col>
        <Col md={6} lg={4} className={'mb-5'}>
          <Feature
            icon={faUsers}
            title={'Exlusive Members Hub Just For You'}
            body={
              'Become part of an exclusive community, earn points and redeem points for epic gaming rewards.'
            }
          />
        </Col>
        <Col md={6} lg={4} className={'mb-5'}>
          <Feature
            icon={faTrophy}
            title={'Tournaments & Challenges'}
            body={
              'Play in tournaments and challenges for your favourite games, and show off your accomplishments.'
            }
          />
        </Col>
        <Col md={6} lg={4} className={'mb-5'}>
          <Feature
            icon={faUserLock}
            title={'Community Game Servers'}
            body={'Get access to community game servers for over 100 games.'}
          />
        </Col>
      </Row>
      <FeaturesModal
        show={showFeaturesModal}
        onHide={() => setShowFeaturesModal(false)}
      />
    </Container>
  );
};

export default Features;
