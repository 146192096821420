const supportedLanguages = [
  {
    id: '1',
    code: 'en',
    backendCode: 'en_US',
    name: 'English',
  },
];

export default supportedLanguages;
