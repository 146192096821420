import { QueryResult } from '@apollo/client';
import {
  GetAllSubscriptionTypesQuery,
  GetAllSubscriptionTypesQueryVariables,
  SubscriptionState,
  SwarmioSubscriptionFragment,
} from '../generated/graphql';
import { FREE_SUBSCRIPTION_TYPE } from 'src/pages/Subscriptions/FreeSubscriptionData';

export const getAllEnrollableSubscriptionTypes = (
  subscriptionPackagesQuery: QueryResult<
    GetAllSubscriptionTypesQuery,
    GetAllSubscriptionTypesQueryVariables
  >
) =>
  [
    // enrollable packages + free package
    FREE_SUBSCRIPTION_TYPE(),
    ...(subscriptionPackagesQuery.data?.getAllSubscriptionTypes ?? []),
  ]
    .filter((subscriptionPackage) => subscriptionPackage.enrollable)
    .sort((a, b) => {
      if (typeof a.weight !== 'number') {
        return -1;
      }
      if (typeof b.weight !== 'number') {
        return 1;
      }
      return a.weight - b.weight;
    });

// Assumes only a single subscription is active at a time.
export const getExistingSubscription = (
  subscriptions: SwarmioSubscriptionFragment[]
) =>
  subscriptions.find((subscription) =>
    [
      SubscriptionState.Active,
      SubscriptionState.New,
      SubscriptionState.AwaitingPayment,
      SubscriptionState.AwaitingInitialPayment,
      SubscriptionState.PartialPayment,
      SubscriptionState.NeedsReviewUsable,
      SubscriptionState.PreparingToCancel,
    ].includes(subscription.subscriptionState)
  );
