import { DotGroup } from 'pure-react-carousel';
import styles from '../Carousel.module.scss';

interface ICarouselDotsProps {
  className?: string;
}

const SlideCounter = (props: ICarouselDotsProps) => (
  <DotGroup className={[styles.carouselDots, props.className].join(' ')} />
);

export default SlideCounter;
