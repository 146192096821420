import Button from 'src/components/Button/Button';
import { Link } from 'react-router-dom';
import Background from '../../../../../components/Background/Background';
import BottomBannerImage from './bottom_banner.png';
import styles from './BottomBanner.module.scss';
import Routes from 'src/config/Routes/Routes';
import { Container } from 'react-bootstrap';

const BottomBanner = () => (
  <Container fluid className={styles.container}>
    <Background
      background={BottomBannerImage}
      noGradient
      position={'center center'}
    >
      <div className={styles.innerContainer}>
        <p className={styles.mediumText}>Take your gaming to</p>
        <p className={styles.giantText}>Another Level</p>
        <Button
          as={Link as any}
          to={Routes.subscribe}
          semantic={'subscriptions-landing-page-subscribe-cta'}
        >
          Subscribe
        </Button>
      </div>
    </Background>
  </Container>
);

export default BottomBanner;
