import { Fragment } from 'react';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const NavButtons = () => {
  const carouselControlClasses = [
    'btn',
    'btn-outline-primary',
    'py-2',
    'mx-auto',
    'd-block',
    'color-white',
  ].join(' ');

  const prevBtn = (
    <ButtonBack className={carouselControlClasses}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </ButtonBack>
  );

  const nextBtn = (
    <ButtonNext className={carouselControlClasses}>
      <FontAwesomeIcon icon={faChevronRight} />
    </ButtonNext>
  );

  return (
    <Fragment>
      {prevBtn}
      {nextBtn}
    </Fragment>
  );
};

export default NavButtons;
