import { useState, useEffect, useContext } from 'react';
import { CarouselContext } from 'pure-react-carousel';
import styles from '../Carousel.module.scss';

interface ISlideCounterProps {
  className?: string;
  slideNumber?: number;
  totalSlides: number;
}

const SlideCounter = (props: ISlideCounterProps) => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    carouselContext.state.currentSlide
  );

  useEffect(() => {
    const onChange = () => {
      setCurrentSlideIndex(carouselContext.state.currentSlide);
    };
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <span className={props.className || styles.slideCounter}>
      {String(props.slideNumber || currentSlideIndex + 1).padStart(2, '0')}
      <span className={'ms-2 me-2'}>/</span>
      {String(props.totalSlides).padStart(2, '0')}
    </span>
  );
};

export default SlideCounter;
