import Card from 'react-bootstrap/Card';

import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'src/components/Button/Button';
import { Link } from 'react-router-dom';
import styles from './Plan.module.scss';
import Routes from '../../../../../config/Routes/Routes';
import Price from '../../../../../components/Packages/Price/Price';
import {
  PaymentFrequency,
  RealWorldCurrencyTypeFinalMix,
} from 'src/generated/graphql';

interface Props {
  id: string;
  title: string | null;
  description: string;
  costs: {
    amount: number;
    currency: RealWorldCurrencyTypeFinalMix;
    frequency: PaymentFrequency;
  }[];
  className?: string;
  stats?: JSX.Element;
}

const Package = (props: Props) => {
  const { t } = useTranslation('subscribe');

  return (
    <Card className={['w-100', 'mb-3', props.className].join(' ')}>
      <Card.Body>
        <Container className={'text-center text-lg-start'}>
          <Row>
            <Col xs={12} lg={9}>
              <div
                className={
                  'd-flex flex-column align-items-center align-items-lg-start'
                }
              >
                <h2 className={'text-primary'}>{props.title}</h2>
                <div
                  className={'text-white mb-4'}
                  dangerouslySetInnerHTML={{
                    __html: props.description,
                  }}
                />
                {props.stats}
              </div>
            </Col>
            <Col xs={12} lg={3} className={styles.rightColumn}>
              <div
                className={
                  'mt-5 mt-lg-2 mb-lg-2 d-flex flex-wrap justify-content-center w-100'
                }
              >
                {props.costs.length ? (
                  <h3 className={'h1 w-100 text-center'}>
                    <Price
                      amount={props.costs[0].amount}
                      currencyCode={props.costs[0].currency}
                      paymentFrequency={props.costs[0].frequency}
                    />
                  </h3>
                ) : (
                  <h3 className={'h1 w-100 text-center'}>{t('price.free')}</h3>
                )}
                <Button
                  semantic={'subscriptions-landing-page-plan-subscribe'}
                  className={'mt-auto'}
                  as={Link as any}
                  to={Routes.createAccount}
                >
                  {t('subscribe')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Package;
