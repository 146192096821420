import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'SLT eSports',
  clientName: 'SLT eSports',
  copyrightName: 'SLT eSports',
  clientOrganizationSiteUrl: 'https://www.slt.lk/esports',
  accountSetupRoute: Routes.subscribe,
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  socialLinks: {
    facebook: 'https://www.facebook.com/SLTeSports/',
    twitter: 'https://twitter.com/SriLankaTelecom',
    youtube: 'https://www.youtube.com/user/srilankatelecom',
    discord: 'https://discord.gg/UJr33KQrky',
  },
  nav: {
    orientation: 'vertical',
  },
  navigation: {
    ...defaultGlobalClientConfig.navigation,
    eventCategories: true,
  },
  currency: {
    showPlusTaxLabel: true,
    showOnlyPoints: true,
  },
  msisdn: {
    requiredness: MsisdnRequiredness.ABSENT,
  },
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
  colors: {
    ...defaultGlobalClientConfig.colors,
    darkGrey: '#555c8b',
    lightGrey: '#a0a8c2',
    black: '#000510',
    white: '#ffffff',
    primary: '#d12630',
    secondary: '#555c8b',
    danger: '#d12630',
    success: '#80ff80',
    warning: '#ffdd00',
    dark: '#111226',
    light: '#d12630',
  },
};

export default GlobalClientConfig;
