import { Modal, ModalProps } from 'react-bootstrap';
import { ButtonNext } from 'pure-react-carousel';
import Carousel from 'src/components/Carousel/Carousel';
import FeaturesModalSlide from './FeaturesModalSlide';
import {
  faSignal,
  faSignalStream,
  faTrophy,
  faUserLock,
  faUsers,
} from 'HiveClient/components/Icons/Icons';
import Data from './DATA.png';
import Hub from './HUB.png';
import Latency from './LATENCY.png';
import PrivateServers from './private_servers.png';
import Tournaments from './tournaments.png';
import styles from './Features.module.scss';
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const items = [
  <FeaturesModalSlide
    title={'OP Levels of Data'}
    body={
      <div>
        <p>
          With SLT’s Gamer Packages, you can finally unlock your gaming
          experience to it’s full potential. Get additional Anytime Data towards
          your gaming activities - be it downloading games, playing, or watching
          streams.
        </p>

        <p>
          Different packages will include extra Anytime Data from different
          sources. Check out the details in each package to find out where your
          additional Anytime Data can count towards!
        </p>

        <p>
          To give you a quick idea, your extra Anytime Data can count towards
          the following:
        </p>

        <ul>
          <li>PUBG Mobile</li>
          <li>Free Fire</li>
          <li>Call of Duty Mobile</li>
          <li>Steam</li>
          <li>Origin</li>
          <li>Twitch</li>
        </ul>
      </div>
    }
    icon={faSignalStream}
    backgroundImage={Data}
  />,
  <FeaturesModalSlide
    title={'Optimized Latency'}
    backgroundImage={Latency}
    body={
      <div>
        <p>
          Have the lowest latency to all your favourite games and get the
          smoothest live-stream viewing experience you ever imagined.
        </p>
        <p>
          With regular internet, your connection to the game servers bounce
          through different locations before they reach the actual game servers.
          This causes all the issues with lag, packet loss, and all those times
          you missed a headshot.
        </p>
        <p>
          With SLT’s Gamer Package, you are connected directly to the game
          servers in the shortest possible path. Not only do you get great
          download and upload speeds, your ping times to these game servers are
          best in the country.
        </p>
      </div>
    }
    icon={faSignal}
  />,
  <FeaturesModalSlide
    title={'Exclusive Members Hub Just For You'}
    backgroundImage={Hub}
    body={
      <div>
        <p>
          Become part of a community exclusive for SLT Gamer Internet
          Subscribers. Get your own Digital Member’s Card, and get access to all
          the cool events on the platform.
        </p>
        <p>
          Earn points in the Member’s Hub for playing in events, watching
          streams, or referring friends! When you have enough points, redeem
          those points for epic gaming rewards - such as gaming hardware, and
          in-game skins.
        </p>
      </div>
    }
    icon={faUsers}
  />,
  <FeaturesModalSlide
    title={'Tournaments & Challenges'}
    backgroundImage={Tournaments}
    body={
      <div>
        <p>
          Play and compete in tournaments for your favourite game titles, on
          competition modes with different formats, settings and all that’s
          needed to feel like a true pro! Create teams, connect with your
          friends, challenge them, all on your SLT gaming hub.
        </p>
        <p>
          Enjoy different challenges for multiple game titles, playing the games
          you love and earning rewards for it. Improve your daily gaming
          experience by adding some friendly competition to it and engage with
          the community in fun and creative ways.
        </p>
        <p>
          As a content creator with a Streamer Internet Package, not only do you
          get the best upload speeds and limits, you can create your own
          tournaments and challenges to engage with your fans.
        </p>
      </div>
    }
    icon={faTrophy}
  />,
  <FeaturesModalSlide
    title={'Community Game Servers'}
    backgroundImage={PrivateServers}
    body={
      <div>
        <p>
          Play on a 24/7 playground in more than 100 games to sharpen your
          skills, warm up for tournaments, play with influencers, or have a
          server to play with your friends.
        </p>
        <p>Some of the game servers:</p>
        <ul>
          <li>Minecraft</li>
          <li>Ark</li>
          <li>Rust</li>
          <li>CS:GO</li>
          <li>And 100 more</li>
        </ul>
        <p>Be creative, be free and enjoy your favorite games on your terms!</p>
      </div>
    }
    icon={faUserLock}
  />,
];

const FeaturesModal = (props: ModalProps) => (
  <Modal
    {...props}
    dialogClassName={'modal-full-width'}
    contentClassName={'overflow-hidden'}
    animation={false}
  >
    <Modal.Body className={'p-0 position-relative'}>
      <Carousel
        isIntrinsicHeight={true}
        showSlideCounter={true}
        infiniteScroll={true}
        navigation={
          <ButtonNext
            className={['btn btn-hollow', styles.nextButton].join(' ')}
          >
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              className={styles.nextButtonIcon}
            />
          </ButtonNext>
        }
      >
        {items}
      </Carousel>
    </Modal.Body>
  </Modal>
);

export default FeaturesModal;
