import { ClientRoute } from 'HiveClient/config/Routes/Routes';
import Product from '../../pages/Product/Product';

// these CANNOT be used outside of the client pack they belong to.
export const ClientRoutePaths = {
  landing: '/landing',
};

export const ClientRoutes: ClientRoute[] = [
  {
    component: Product,
    path: ClientRoutePaths.landing,
  },
];

export default ClientRoutes;
