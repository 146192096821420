import { useState, useRef, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { CarouselProvider } from 'pure-react-carousel';
import NavButtons from './Components/NavButtons';
import CarouselSlider from './Components/CarouselSlider';
import CarouselDots from './Components/CarouselDots';
import SlideCounter from './Components/SlideCounter';

const DYNAMIC_SLIDER_CARD_WIDTH = 243.5;

export interface ICarouselProps {
  children: JSX.Element | JSX.Element[];
  fullHeight?: boolean;
  hideNavigation?: boolean;
  hideDots?: boolean;
  infiniteScroll?: boolean;
  showSlideCounter?: boolean;
  responsive?: { width: number; items: number }[];
  navigation?: JSX.Element;
  isIntrinsicHeight: boolean;
  isPlaying?: boolean;
  interval?: number;
  classes?: {
    div?: string;
    carousel?: string;
    slider?: string;
    sliderTray?: string;
    slide?: string;
    animation?: string;
    trayWrap?: string;
  };
  slideSnapMode?: string | 'none' | 'last-slide-snap';
}

const Carousel = (props: ICarouselProps) => {
  const [visibleSlides, setVisibleSlides] = useState(1);
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver<HTMLDivElement>({
    ref: ref,
  });

  const totalSlides = Array.isArray(props.children) ? props.children.length : 1;
  const showSlideCounter = props.showSlideCounter && totalSlides > 1;
  const showDots = !props.hideDots && totalSlides > 1;
  const showNavigation =
    !props.hideNavigation && totalSlides > 1 && visibleSlides < totalSlides;

  useEffect(() => {
    if (props.slideSnapMode === 'last-slide-snap') {
      const maxVisibleSlides = width / DYNAMIC_SLIDER_CARD_WIDTH;
      setVisibleSlides(maxVisibleSlides);
    } else if (props.responsive) {
      // sort then find the largest responsive width that the current screen width accomodates
      // use that item limit for the visibleSlides
      const size = props.responsive
        .sort((sizeA, sizeB) => sizeB.width - sizeA.width)
        .find((size) => width >= size.width);

      let maxVisibleSlides = size?.items || 1;

      setVisibleSlides(
        totalSlides < maxVisibleSlides ? totalSlides : maxVisibleSlides
      );
    }
  }, [props.slideSnapMode, props.responsive, totalSlides, width]);

  return (
    <div ref={ref} className={props.classes?.div}>
      <CarouselProvider
        className={props.classes?.carousel}
        isIntrinsicHeight={props.isIntrinsicHeight}
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        totalSlides={totalSlides}
        dragEnabled={false}
        touchEnabled={totalSlides > 1 && visibleSlides < totalSlides}
        orientation={'horizontal'}
        infinite={props.infiniteScroll}
        playDirection='backward'
        visibleSlides={visibleSlides}
        step={visibleSlides}
        interval={props.interval}
        isPlaying={props.isPlaying}
      >
        <CarouselSlider fullHeight={props.fullHeight} classes={props.classes}>
          {props.children}
        </CarouselSlider>
        <div
          className={
            'd-flex align-items-center justify-content-center w-100 m-auto'
          }
        >
          {showNavigation && (props.navigation || <NavButtons />)}
          {showDots && <CarouselDots />}
          {showSlideCounter && <SlideCounter totalSlides={totalSlides} />}
        </div>
      </CarouselProvider>
    </div>
  );
};

export default Carousel;
