import BackgroundImage from './background.png';
import Flare from './flare.png';
import Boy from './boy.png';
import Background from '../../../../../components/Background/Background';
import styles from './Hero.module.scss';
import { Container } from 'react-bootstrap';

const Hero = () => (
  <Background
    clearMenu
    noGradient
    background={BackgroundImage}
    position={'center center'}
    className={'overflow-hidden'}
  >
    <Container className={styles.container}>
      <div className={styles.copyContainer}>
        <h1>
          <span className={styles.mediumHeader}>
            <span>Internet package</span>
            <br />
            <span>designed by gamers</span>
          </span>
          <span className={styles.giantHeader}>for gamers</span>
        </h1>
        <img src={Flare} alt={'decorative flare'} className={styles.flare} />
        <div>
          <div>
            <p className={styles.redText}>Extra Data</p>
            <p className={styles.redTextDescription}>
              For gaming and game downloads
            </p>
          </div>
          <div>
            <p className={styles.redText}>Lowest Latency</p>
            <p className={styles.redTextDescription}>
              Best ping for your best gaming experience
            </p>
          </div>
          <div>
            <p className={styles.redText}>Exclusive members hub</p>
            <p className={styles.redTextDescription}>
              Gaming members hub with tournaments and challenges
            </p>
          </div>
        </div>
      </div>
      <div className={styles.boyContainer}>
        <div
          className={styles.boy}
          style={{ backgroundImage: `url(${Boy})` }}
        />
      </div>
    </Container>
  </Background>
);

export default Hero;
