import {
  Locale,
  SubscriptionState,
  SubscriptionTypeFragment,
  SwarmioSubscriptionFragment,
} from 'src/generated/graphql';
import { getI18n } from 'react-i18next';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

export const FREE_SUBSCRIPTION_ID = 'FREE_SUBSCRIPTION_ID';

export const FREE_SUBSCRIPTION_TYPE: () => SubscriptionTypeFragment = () => {
  const i18n = getI18n();

  return {
    id: FREE_SUBSCRIPTION_ID,
    localizedNames: [
      {
        locale: Locale.EnUs,
        value: i18n.t('free-member-subscription.package.name', {
          clientName: GlobalClientConfig.clientName,
        }),
        __typename: 'LocalizedString',
      },
    ],
    localizedDescriptions: [
      {
        // Doesn't actually matter what locale is set here, just has to be something.
        locale: Locale.EnUs,
        value: i18n.t('free-member-subscription.package.description', {
          clientName: GlobalClientConfig.clientName,
        }),
        __typename: 'LocalizedOptionalString',
      },
    ],
    weight: 0,
    enrollable: true,
    initialSwarmioCurrencyReward: null,
    recurringPayoutBonus: null,
    explicitEnrollment: null,
    implicitEnrollments: [],
    __typename: 'SubscriptionType',
  };
};

export const FREE_SWARMIO_SUBSCRIPTION = (): SwarmioSubscriptionFragment => ({
  __typename: 'ExplicitSubscription',
  id: FREE_SUBSCRIPTION_ID,
  subscriptionState: SubscriptionState.Active,
  subscriptionType: FREE_SUBSCRIPTION_TYPE(),
  subscriptionTypeCostId: 'FAKE',
  subscriptionTypeId: FREE_SUBSCRIPTION_TYPE().id,
});
