import { PaymentFrequency } from '../../../generated/graphql';
import { subscriptionRecurringPaymentFrequencyLabel } from '../../../utilities/Subscriptions';
import { formatCurrencyToPartsForLocale } from '../../../utilities/Utilities';

interface IProps {
  amount: number;
  currencyCode: string;
  paymentFrequency: PaymentFrequency;
}

const Price = (props: IProps) => {
  const currencyParts = formatCurrencyToPartsForLocale(
    props.amount,
    props.currencyCode
  );

  const amount = currencyParts.map(({ type, value }) => {
    switch (type) {
      case 'fraction':
      case 'decimal':
        return '';
      case 'currency':
        return (
          <small key='currency' className={'h4 text-light'}>
            {value}
          </small>
        );
      default:
        return value;
    }
  });

  const cents = currencyParts.map(({ type, value }) => {
    switch (type) {
      case 'decimal':
      case 'fraction':
        return value;
      default:
        return '';
    }
  });

  return (
    <div>
      {amount}
      <small className={'h4'}>
        <div className={'d-inline-block text-light'}>
          {cents}
          <br />
          {subscriptionRecurringPaymentFrequencyLabel(props.paymentFrequency)}
        </div>
      </small>
    </div>
  );
};

export default Price;
