import { useTranslation } from 'react-i18next';
import Stat from '../../../../components/Packages/Stat/Stat';
import AnytimeDataModal from 'HiveClient/components/Modals/AnytimeDataModal';
import { SubscriptionTypeFragment } from '../../../../generated/graphql';
import { isTypename } from '../../../../utilities/TSUtilities';

interface IProps {
  className?: string;
  subscriptionType: SubscriptionTypeFragment;
}

const getDataQuotaStringFromSubscriptionType = (
  subscriptionType: SubscriptionTypeFragment
) => {
  // Theoretically a subscriptionType can be referenced by multiple different
  // externalSubscriptionPackages, but SLT is not using that functionality.
  const externalSubscriptionPackage =
    subscriptionType.implicitEnrollments.length &&
    isTypename(
      'SLTProviderExternalSubscriptionPackage',
      subscriptionType.implicitEnrollments[0].externalSubscriptionPackage
    )
      ? subscriptionType.implicitEnrollments[0].externalSubscriptionPackage
      : null;

  return externalSubscriptionPackage
    ? `${externalSubscriptionPackage.dataQuota ?? 0} GB`
    : undefined;
};

export const AnytimeDataStat = (props: IProps) => {
  const { t } = useTranslation('subscribe');
  const dataQuota = getDataQuotaStringFromSubscriptionType(
    props.subscriptionType
  );

  if (!dataQuota) {
    return null;
  }

  return (
    <Stat
      title={t('package-card.additional-quota')}
      stat={dataQuota}
      toolTipModal={AnytimeDataModal}
      className={props.className}
    />
  );
};
