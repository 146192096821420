import { Col, Container, Row } from 'react-bootstrap';
import COD from './COD_MOBILE.png';
import CSGO from './CSGO.png';
import FREEFIRE from './FREEFIRE.png';
import VALORANT from './VALORANT.png';
import PUBG from './PUBG_MOBILE.png';
import MORE_GAMES from './MORE_GAMES.png';
import GameTile from './GameTile/GameTile';

const Games = () => (
  <Container className={'my-5'}>
    <Row>
      <Col xs={6} md={4} lg={2}>
        <GameTile img={PUBG} title={'PUBG: Mobile'} />
      </Col>
      <Col xs={6} md={4} lg={2}>
        <GameTile img={COD} title={'Call of Duty: Mobile'} />
      </Col>
      <Col xs={6} md={4} lg={2}>
        <GameTile img={FREEFIRE} title={'Free Fire'} />
      </Col>
      <Col xs={6} md={4} lg={2}>
        <GameTile img={VALORANT} title={'Valorant'} />
      </Col>
      <Col xs={6} md={4} lg={2}>
        <GameTile img={CSGO} title={'Counter Strike: Global Offensive'} />
      </Col>
      <Col xs={6} md={4} lg={2}>
        <GameTile img={MORE_GAMES} title={'+ Many More Games'} />
      </Col>
    </Row>
  </Container>
);
export default Games;
