import styles from './FancyButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonProps } from '../../../../components/Button/Button';

export interface Props extends Omit<ButtonProps, 'variant'> {
  // Used when doing `as={Link as any}`, will pass the `to` value to the React Router Link element.
  to?: string;
  // A flag for when the button should have no visible behaviour (hover, active, etc.)
  presentational?: boolean;
  highlight?: boolean;
  scaleOnHover?: boolean;
  scaleUp?: boolean;
}

const FancyButton = (props: Props) => {
  const {
    presentational,
    highlight,
    children,
    className,
    semantic,
    scaleOnHover,
    scaleUp,
    ...rest
  } = props;
  return (
    <Button
      {...rest}
      semantic={semantic}
      variant={''}
      className={[
        className,
        styles.button,
        presentational ? styles.presentational : '',
        scaleOnHover ? styles.scaleOnHover : '',
        scaleUp ? styles.scaleUp : '',
      ].join(' ')}
    >
      <span className={styles.buttonText}>{props.children}</span>
      {/* hide the button decoration when it's presentational or highlight */}
      {!props.presentational && !props.highlight && (
        <span className={styles.buttonDecoration}>
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      )}
    </Button>
  );
};

export default FancyButton;
