import { useState, ElementType } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from 'HiveClient/components/Icons/Icons';
import { ModalProps } from 'react-bootstrap';
import Button from 'src/components/Button/Button';

interface Props {
  title: string;
  stat: string;
  toolTipModal?: ElementType<ModalProps>;
  className?: string;
}

const Stat = (props: Props) => {
  const [showToolTipModal, setShowToolTipModal] = useState<boolean>(false);
  const Modal = props.toolTipModal ?? null;

  return (
    <div className={['d-flex flex-column', props.className].join(' ')}>
      <span className={'text-dark copy-small'}>
        {props.title}{' '}
        {props.toolTipModal && (
          <Button
            semantic={'package-tooltip-toggle'}
            onClick={() => setShowToolTipModal(true)}
            variant={'hollow'}
            className={'p-0 text-dark'}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Button>
        )}
      </span>
      <span className={'fw-bold copy-large'}>{props.stat}</span>
      {Modal ? (
        <Modal
          show={showToolTipModal}
          onHide={() => setShowToolTipModal(false)}
        />
      ) : null}
    </div>
  );
};

export default Stat;
